@import "../../../assets/scss/styles.scss";

.preparing-dashboard {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1500;
  // margin-top: 100px;

  .preparing-dashboard-content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.primary-color-button-fix {
  background-color: #05386b !important;
  color: white;
}

//--------Main dashboard-----------//
.main-dash-blog {
  max-width: 100%;
}

.sidebar-background {
  // background-color: #ffffff;
}

.main-dashboard {
  margin-left: 15px;
  margin-right: 15px;
}

.content.learner-main-dashboard {
  padding: 0 !important;
  background-color: #f8f8fb;
}

.learner-main-dashboard-container {
  padding: 0 24px 10px 24px;
}

.dashboard {
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #e6ebf0;
  border-bottom: 1px solid #e6ebf0;

  .dashboard-header {
    font-size: 13px;
    font-weight: 500;
    color: #05386b;
    margin: 16px 24px;
  }

  &-task {
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;

    .dashboard-task-background {
      position: absolute;
      bottom: 75;
      right: 0;
      z-index: 10;
    }

    .dashboard-content-1 {
      border-radius: 4px;
      background: #f8f8fb;
      padding: 16px;
      margin-top: 8px;
    }
  }

  &-profile {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 18px;

    &_image {
      height: 48px;
      width: 48px;
      border-radius: 40px;
    }

    &_header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #5cdb95;
    }

    &_span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #05386b;
      margin-top: 20px;
    }

    &_para {
      font-size: 13px;
      font-weight: 500;
      color: #3e6d9c;
      margin-top: -5px;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    padding-bottom: 25px;
    position: relative;
    margin-top: 16px;
  }

  // dashboard - material //
  &-material {
    // margin-left: 16px;
    display: flex;
    flex-direction: row;

    &_icon {
      height: 48px;
      width: 48px;
      padding: 2px;
      border-radius: 50%;
      background-color: #ffffff;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_book {
      color: #5cdb95;
      font-size: 20px;
    }

    &_header {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #5cdb95;
    }

    &_para {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #3e6d9c;
      margin-top: -10px;
      margin-right: 10px;
      width: max-content;
    }
  }

  &-session {
    // height: 137px;
    // width: 231px;
    border-radius: 4px;
    background-color: #f8f8fb;
    margin-left: 15px;
    display: flex;
    flex-direction: row;

    &_icon {
      height: 48px;
      width: 48px;
      border-radius: 50px;
      background-color: #ffffff;
      margin-top: 20px;
      margin-left: 20px;
    }

    &_wallet {
      color: #5cdb95;
      font-size: 20px;
      margin-left: 14px;
      margin-top: 14px;
    }

    &_header {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #5cdb95;
    }

    &_para {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #3e6d9c;
      margin-top: -10px;
      margin-right: 20px;
      width: max-content;
    }
  }

  &-userimg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 11;

    &_bg {
      width: 100%;
      background: transparent;
      object-fit: contain;
    }

    @include respond-below(sm) {
      display: none;
    }
  }

  &-footer {
    position: relative;

    &_flex {
      position: absolute;
      top: -80px;
      left: 30px;
    }

    &_title {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #05386b;
    }

    &_para {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 80px;
      color: #05386b;
    }

    &_flex1 {
      position: absolute;
      top: -80px;
      left: 260px;
    }

    &_title1 {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #05386b;
    }

    &_para1 {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 90px;
      color: #05386b;
    }

    &_box1 {
      height: 5px;
      width: 199px;
      border-radius: 4px;
      background: linear-gradient(to right, #5cdb95, 20%, #e6ebf0);

      // background: #5cdb95;
    }
  }
}

//--------- Dashboard Booking--------- ///

.booking {
  border-radius: 4px;

  .active {
    border-bottom: 2.5px solid $secondary-color;
  }

  &-header {
    width: 100%;
    border-bottom: 1px solid #e6ebf0;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding-bottom: 12px;
  }

  &-header1 {
    margin-top: 8px;

    &_task {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #3e6d9c;
      padding: 12px 24px;
      cursor: pointer;
    }
  }

  // Booking content //

  .booking-content-container {
    background: #ffffff;
    padding-right: 16px;
  }

  .booking-content-container1 {
    // background: red;
    height: 380px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px transparent;
      -webkit-box-shadow: inset 0 0 6px transparent;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6ebf0;
      border: 2px solid transparent;
    }
  }

  .booking-content_session2 {
    margin: 0px 24px 0px 24px;
    padding-top: 24px;
    padding-bottom: 10px;
  }

  &-content {
    .booking-content_session1 {
      border-bottom: 2px solid #e6ebf0;
      margin: 0px 24px 0px 24px;
      padding-top: 24px;
      padding-bottom: 20px;
    }

    &_image,
    &_image1 {
      height: 52px;
      width: 52px;
      border-radius: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_image {
      background-color: #f0f9fd;
      border: 1px solid #48b3e0;
    }

    &_image1 {
      background-color: #fefbe8;
      border: 1px solid #f3d11a;
    }

    &_icon {
      font-size: 20px;
      color: #48b3e0;
    }

    &_icon1 {
      font-size: 20px;
      color: #f3d11a;
    }

    &_main {
      position: relative;
      margin-left: 12px;
    }

    &_subheader {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #05386b;
    }

    &_span1 {
      color: #48b3e0;
      font-weight: 400;
    }

    &_span2 {
      color: #48b3e0;
      font-weight: 400;
    }

    &_footer {
      display: flex;
      flex-direction: row;
    }

    &_subheader2 {
      font-size: 13px;
      font-weight: 500;
      line-height: 19.5px;
      margin: auto 0;
    }

    &_subheader3 {
      font-size: 13px;
      font-weight: 500;
      line-height: 19.5px;
    }

    &_box {
      width: 51px;
      margin: auto 12px; // 7px
      // border-radius: 5px;
      // margin-top: 7px;
      // margin-right: 10px;
      // background: linear-gradient(to right, #5cdb95, 78%, #e6ebf0);
    }

    &_span3 {
      color: #8ca6c0;
      font-size: 13px;
      font-weight: 500;
    }

    &_span4 {
      color: #8ca6c0;
      font-size: 13px;
      font-weight: 500;
    }

    &_btn {
      padding: 4px 16px 4px 16px !important;
    }

    &_span5 {
      font-size: 13px;
      font-weight: 500;
      color: black;
    }

    &_btn1 {
      position: absolute;
      top: -60px;
      left: 35.4rem;
      height: 40px;
      width: max-content;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    justify-content: center;
    align-items: center;
    margin: 0 24px;
    margin-top: 20px;

    @include respond-below(sm) {
      flex-direction: column;
    }

    &_material,
    &_tutor {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 16px 63px;
      border-radius: 4px;
      justify-content: center;
      align-items: center;

      @include respond-below(md) {
        padding: 16px 50px;
      }
    }

    &_material {
      background-color: #ecf9f3;
      margin-right: 10px;

      @include respond-below(sm) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    &_tutor {
      background-color: #f0f9fd;
    }

    &_book {
      color: #5cdb95;
      margin-right: 16px;
    }

    &_head,
    &_head1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    &_head {
      color: #5cdb95;
    }

    &_user {
      color: #48b3e0;
      margin-right: 16px;
    }

    &_head1 {
      color: #48b3e0;
    }
  }
}

//------------setup profile - dashoboard ---------------//

.setup-profile-main {
  background-color: #f8f8fb;
}

.setup-profile {
  background-color: #f8f8fb;
  margin-top: 70px;
  margin-right: 12px;

  .profile {
    margin-left: 12px;

    .profile-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;

      @include respond-below(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-routes {
      padding-top: 16px;
      color: $secondary-color-3;

      &_title {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $secondary-color-3;
        margin-right: 2px;
      }

      &_span {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $secondary-color;
      }
    }

    .profile_header {
      font-size: 24px;
      color: #05386b;
      font-weight: 600;
    }

    .profile_header1 {
      font-size: 12px;
      color: #3e6d9c;
      font-weight: 400;
      margin-top: 8px;
    }

    .profile-card {
    }

    //Card session//
    .profile-card-session {
      width: 100%;
      background-color: #ffffff;
      border-radius: 3.25px;
      position: relative;
      margin-top: 24px;

      @include respond-below(sm) {
        margin-bottom: 20px;
      }

      .profile-card-session_main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        padding-top: 61px;
        padding-bottom: 60px;
      }

      .profile-card-session_record {
        height: 62px;
        width: 62px;
        border-radius: 46px;
        background-color: #f0f9fd;
        border: 1px solid #48b3e0;
      }

      .profile-card-session_video {
        font-size: 20px;
        margin-left: 20px;
        color: #48b3e0;
        margin-top: 20px;
      }

      .profile-card-session_header {
        font-size: 18px;
        font-weight: 630;
        line-height: 28px;
        height: 56px;
        width: 215px;
        margin-top: 32px;
        color: #05386b;
      }

      .profile-card-session_btn {
        margin-top: 16px;
      }

      .profile-card-session_img-container {
        position: relative;
        border: 1px solid red;
        position: absolute;
        top: 52px;
        left: 257px;
      }

      .profile-card-session_img1 {
        height: 88px;
        width: 89px;
        border-radius: 46px;
        position: absolute;
        top: 52px;
        right: 71.11px;

        @include respond-below(sm) {
          height: 60px;
          width: 60px;
          top: 25px;
          left: 175px;
        }
      }

      .profile-card-session_img2 {
        height: 62px;
        width: 63px;
        border-radius: 46px;
        position: absolute;
        top: 142px;
        right: 142px;

        @include respond-below(sm) {
          height: 45px;
          width: 45px;
          top: 100px;
          left: 150px;
        }
      }

      .profile-card-session_img3 {
        height: 94px;
        width: 93px;
        border-radius: 46px;
        position: absolute;
        top: 148.63px;
        right: 38.57px;
        box-shadow: 4px 3px 1px rgb(250, 250, 250);

        @include respond-below(sm) {
          height: 70px;
          width: 70px;
          top: 85px;
          left: 215px;
        }
      }
    }

    //Profile Card Connect //
    .profile-card-connect {
      // height: 315px;
      // width: 100%;
      margin-top: 24px;
      color: #ffffff;
      background-color: #3e6d9c;
      border-radius: 8px;
      box-shadow: 0px 11px 0px -2px #b8c7d6;

      @include respond-below(sm) {
        margin-bottom: 20px;
      }

      .profile-card-connect_content {
        padding-top: 24px;
        padding-left: 24px;
      }

      .profile-card-connect_img {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        margin-top: 30px;
        margin-bottom: 36px;
      }

      .profile-card-connect_face {
        position: relative;
      }

      .profile-card-connect_head {
        color: #ffffff;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
      }

      .profile-card-connect_circle {
        height: 86px;
        width: 87px;
        border-radius: 46px;
        background-color: #f8f8fb;
        border: 1px solid #5cdb95;
      }

      .profile-card-connect_circleHead {
        height: 38px;
        width: 38px;
        border-radius: 35px;
        background-color: #f8f8fb;
        border: 3.5px solid #3e6d9c;
        position: absolute;
        top: -8px;
        right: -10px;
      }

      .profile-card-connect_circle1 {
        height: 5px;
        width: 5px;
        border-radius: 4px;
        background-color: #ffffff;
      }

      .profile-card-connect_circle2 {
        height: 15px;
        width: 15px;
        border-radius: 13px;
        background-color: #ffffff;
      }

      .profile-card-connect_circle3 {
        height: 5px;
        width: 5px;
        border-radius: 4px;
        background-color: #ffffff;
      }

      .profile-card-connect_circle4 {
        height: 62px;
        width: 62px;
        border-radius: 46px;
        background-color: #f0f9fd;
        border: 1px solid #48b3e0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .profile-card-connect_video {
        font-size: 20px;
        color: #48b3e0;
      }

      .profile-card-connect_head1 {
        color: #ffffff;
        font-size: 24px;
        line-height: 36px;
        margin-top: 32px;
        padding-bottom: 45px;
      }

      .profile-card-connect_span {
        color: #5cdb95;
        text-decoration: underline;
      }
    }

    // Card Material //
    .profile-card-material {
      width: 100%;
      background-color: #ffffff;
      border-radius: 5px;
      position: relative;
      margin-top: 24px;

      .profile-card-material_main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
      }

      .profile-card-material_image {
        height: 62px;
        width: 62px;
        border-radius: 46px;
        background-color: #fefbe8;
        border: 1px solid #f3d11a;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
      }

      .profile-card-material_book {
        font-size: 20px;
        color: #f3d11a;
      }

      .profile-card-material_header {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #05386b;
        margin-top: 32px;
      }

      .profile-card-material_btn-container {
        padding-bottom: 48px;
        margin-top: 16px;
      }

      .profile-card-material_img {
        position: relative;
      }

      .profile-card-material_img1 {
        height: 70px;
        width: 70px;
        border-radius: 46px;
        position: absolute;
        top: 37px;
        right: 66.53px;

        @include respond-below(sm) {
          top: 25px;
          left: 190px;
        }
      }

      .profile-card-material_img2 {
        height: 50.3px;
        width: 50.3px;
        border-radius: 46px;
        position: absolute;
        top: 111.45px;
        right: 114px;

        @include respond-below(sm) {
          top: 85px;
          left: 145px;
        }
      }

      .profile-card-material_img3 {
        height: 80px;
        width: 80px;
        border-radius: 46px;
        position: absolute;
        top: 110px;
        right: 28px;
        box-shadow: 4px 3px 1px rgb(250, 250, 250);

        @include respond-below(sm) {
          top: 105px;
          left: 200px;
        }
      }
    }
  }
}

.setup-learning-material {
  margin-left: 12px;
}

.popular-learning-material {
  &-cards {
    img {
      max-height: 5.75rem;
    }

    .course-landing {
      // height: 340px;
      // width: 267px;
      // border: 1px solid red;
    }
  }
}

//-----------------
.dash-blog {
  width: 100%;

  .dash-blog-right-banner-2-content {
    height: 201px;

    @include respond-below(sm) {
      padding-bottom: 20px;
    }
  }

  .dash-blog-carousel-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 8px;

    .dash-blog-carousel-text {
      position: absolute;
      bottom: 47px;
      left: 27px;
      z-index: 20;
      color: white;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .dash-blog-carousel {
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .dash-blog-carousel-img {
      width: 100%;
      z-index: 10;
      height: 387px;
      object-fit: cover;
      color: rgba(0, 0, 0, 0.294);
      border-radius: 8px;
    }

    .slick-list {
      border-radius: 8px;
    }

    .slick-dots li.slick-active button:before {
      color: #48b3e0;
      z-index: 30;
      position: relative;
      font-size: 8px;
    }

    .slick-dots li button {
      color: white;
    }

    .slick-dots li button:before {
      color: white;
      z-index: 30;
      position: relative;
      font-size: 8px;
    }

    .slick-slider {
      width: 100%;

      .dash-blog-carousel-dots {
        position: absolute;
        left: -15px;
        bottom: 40px;
      }

      .dash-blog-carousel-dots li.slick-active button:before {
        color: #48b3e0;
        z-index: 30;
        position: relative;
        font-size: 8px;
      }

      // .slick-dots {
      //   bottom: 20px;
      //   right: 200px;
      // }

      // @media (max-width: 768) {
      //   .slick-dots {
      //     bottom: 10px;
      //     right: 100px;
      //   }
      // }
    }
  }

  .dash-blog-header {
    color: #05386b;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .dash-blog-container {
    display: flex;
    height: 386px;
  }

  .dash-blog-right-banner-1 {
    position: relative;
    margin-bottom: 24px;
    height: 161px;
  }

  .dash-blog-right-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 1;
  }

  .dash-blog-right-p {
    position: absolute;
    bottom: 0;
    left: 33px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    width: 276px;
    line-height: 36px;
  }

  .dash-blog-right-p-2 {
    position: absolute;
    bottom: 0;
    left: 32px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    width: 220px;
    line-height: 36px;
  }
}

.blogs {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;

  .blog-carousel-container {
    background: lightcoral;

    .blog-carousel-img {
      height: 386px;
      width: 530px;
      object-fit: fill;
    }
  }

  &-header {
    color: #05386b;
    font-weight: 600;
    font-size: 16px;
  }

  &-img1 {
    // background: lightblue;
    border: 1px solid red;
    margin-right: 20px;
    margin-left: -50px;
    margin-top: 30px;
    position: relative;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #ffffff;

    &_tutor {
      height: 386px;
      width: 530px;
      border-radius: 5px;
    }

    &_para {
      position: absolute;
      top: 15rem;
      left: 33px;
    }
  }

  &-img2 {
    margin-top: 30px;
    margin-right: -100px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #ffffff;

    &_money {
      border-radius: 5px;
      margin-right: 24px;
      height: 161px;
      width: 530px;
    }

    &_para1 {
      margin-top: -90px;
      margin-left: 10px;
      width: 280px;
    }
  }

  &-img3 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #ffffff;

    &_content1 {
      height: 206px;
      width: 255px;
      margin-right: 20px;
      border-radius: 8px;
    }

    &_content2 {
      height: 206px;
      width: 255px;
      margin-right: 20px;
      border-radius: 8px;
    }

    &_para2 {
      margin-top: -80px;
      margin-left: 15px;
      width: 170px;
    }
  }
}

.dash-card {
  margin-top: 18px;
  position: relative;
  background: white;
  border-radius: 8px;

  &-title-container {
    padding: 16px;
    padding-bottom: 12px;
  }

  .dash-card-best {
    font-size: 12px;
  }

  .dash-card-title {
    font-size: 14px;
    line-height: 24px;
  }

  &-tags {
    padding: 0 16px;
  }

  &-buttons {
    padding: 16px;
    padding-top: 12px;
  }

  &-button {
    border: 1px solid #05386b !important;
    background-color: white;
  }

  &-like {
    // border: 1px solid red;
    color: #05386b;
  }

  .dash-card-earning {
    font-size: 18px;
    padding: 0 16px;
    padding-bottom: 16px;

    span {
      font-size: 14px;
    }
  }
}

.tutor-dash-card {
  background: white;
  width: 100%;
  border-radius: 4px;
  position: relative;
  border-top-right-radius: 6px;

  .tutor-dash-card-header {
    margin-left: 16px;
  }

  .tutor-dash-button {
    margin-top: 16px;
    margin-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }

  .tutor-dash-card-img-container {
  }

  .tutor-dash-card-img {
    height: 54px;
    width: 54px;
    border-radius: 50px;
    margin-top: 15px;
    object-fit: cover;
  }

  .tutor-dash-card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #1284f7;
    position: absolute;
    top: 16px;
    left: 85px;
  }

  .tutor-dash-card-para {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #8ca6c0;
    position: absolute;
    top: 46px;
    left: 82px;
  }

  .tutor-dash-card-rating {
    position: absolute;
    top: 13px;
    left: 200px;
  }

  .tutor-dash-card-student {
    position: relative;
    margin-top: 24px;

    .tutor-dash-card-student_content {
      margin-top: 4px;
      margin-left: 16px;
    }
  }

  .tutor-dash-card-book {
    margin-right: 4px;
  }

  .tutor-dash-card-para1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .tutor-dash-card-tags {
    margin-top: 16px;
    margin-left: 16px;
  }

  .card-skyblue {
    color: $info;
  }

  .card-green {
    color: $primary-color;
  }

  .card-blue {
    color: $secondary-color;
  }

  .tutor-dash-card-bullet {
    color: #b8c7d6;
    position: absolute;
    top: -27px;
    left: 75px;
  }

  .tutor-dash-card-user {
    color: #5cdb95;
    margin-right: 4px;
  }

  .tutor-dash-card-para2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #5cdb95;
    margin-top: -1.5px;
  }
}

.trending-tutor {
  // &-bio {
  //   height: 50px;
  // }
  &-card {
  }
  &-title {
    color: #272d37;
  }
  &-img {
    width: 54px !important;
    height: 54px !important;
  }
  &-tag {
    display: inline-block;
    padding: 1px 9px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
    color: #3e6d9c;
    background-color: #e6ebf0;
    white-space: nowrap;
  }
  &-more-tag {
    width: fit-content;
    height: fit-content;
    padding: 0;
    background-color: transparent;
    border: 0 !important;
    border-radius: 0 !important;
  }
  &-more-toggler {
    width: fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
  &-more-menu {
    padding: 0.7rem;
    background-color: #f8f8fb;
    border: 1px solid #c8c8c8;
    max-height: 138px;
    overflow-y: auto;
  }
  &-more-item {
    margin: 0.5rem 0;
  }
  &-boughts-box {
    border-radius: 10px;
  }
}

// ----------------- Earning dashboard page ---------------------/
.dash-card {
  padding: 10px 5px 5px 5px;
}
.earning-dash-card-tutor {
  background-color: #f0f9fd;
  border: 1px solid #48b3e0;
}

.earning-dash-card-material {
  background-color: #fefbe8;
  border: 1px solid #f3d11a !important;

  p.text-secondary {
    font-weight: bold;
  }
}

.earning-dash-card-earning {
  background-color: #ecf9f3;
  border: 1px solid #3cbd7f;
}

.withdraw-modal-input.form-group {
  .font-icons {
    position: absolute;
    left: 5px;
    top: 6px;
    font-size: 18px;
    z-index: 1000;
    // line-height: 45px;
    &:hover {
      cursor: pointer;
    }
  }
}

// ------------------ support dashboard ------------------//

.help-center-title {
  font-size: 18px !important;
  line-height: 24px;
  font-weight: 600;

  color: #05386b !important;
}

// search input
.helper-search-input {
  height: 45px !important;
}

// for left side search icon
.helper-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 45px;
  left: 13px;
  top: 0;
  color: #74788d;
}

.helper-img-container {
}

// booksesion css
::placeholder {
  color: #05386b;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #05386b;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #05386b;
}

// booksesion css ends

// righ align code

// .custom-search.form-group {
//   .font-icons {
//       // right: 15px;
//       left: 0;
//       top: 0;
//       z-index: 1000;

//       &:hover {
//           cursor: pointer;
//       }
//   }
// }

.bg-support-info-card {
  background-color: #f8f8fb;
}

// drop message

.drop-msg-desc-text-area {
  min-height: 150px;
  height: 150px m !important;
  padding-left: 1rem !important;
}

// problem details

// nav pan

.nav-tabs-custom .nav-item .nav-link::after {
  background: #05386b;
}

.bg-closed-badge {
  background-color: #fdf3f3;
  color: #ea8686;
  padding: 0.75em;
  font-size: 0.75rem;
}

.bg-open-badge {
  background-color: #ecf9f3;
  color: #5cdb95;
  padding: 0.75em;
  font-size: 0.75rem;
}

// support desciption

.support-description {
  p {
    color: #3e6d9c;
  }
}

.support-resolution {
  p {
    padding: 1rem;
    border: 1px solid #5cdb95;
    border-radius: 8px;
  }
}

textarea.form-control {
  min-height: calc(5.5em + 0.94rem + 2px);
}

.attachment-btn {
  position: relative;
  border: 1px dashed #b8c7d6;
  padding: 11px 18px;
  border-radius: 10px;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.2;
  width: 100%;
  text-align: left;
}
.attachment-btn input[type="file"] {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  transform: scale(5);
  opacity: 0;
}

.blogs-header1 {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #05386b !important;
  margin-bottom: 0 !important;
}

.blogs-header2 {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #05386b !important;
}

// trasactions page

// .transactions-subject-list li{
//   float:left;

//   list-style-type: disc;
// }

.transactions-subject-list .list-group-item {
  color: rgba(140, 166, 192, 0.5);
}
.transactions-subject-list li {
  display: inline-block;
}
.transactions-subject-list li:before {
  content: "\00a0\2022\00a0\00a0";
  color: #b8c7d6;
  color: rgba(140, 166, 192, 0.5);
  font-size: 14px;
}
.transactions-subject-list li:first-child:before {
  content: "";
}
.transactions-subject-list p {
  color: #3e6d9c;
}

// datatable td cell color change

.table-light td {
  color: #3e6d9c;
  font-weight: lighter;
}
